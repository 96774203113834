import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import * as JWT from 'jwt-decode';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseResult } from '../models/response-result';
import { environment } from '../../../environments/environment';
import { VsAuthenService } from '../auth/authen.service';
import { User } from '../models/user';
import { BaseService } from './base.service';
import { VsCommonService } from './vs-common.service';
export class UserService extends BaseService {
    constructor(http, injector, _oauthService, _commonService) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/Users`);
        this._oauthService = _oauthService;
        this._commonService = _commonService;
        this.USER_INFO_KEY = 'user_info';
        this.authenticationEndpoint = `${environment.apiDomain.authenticationEndpoint}/users`;
        this.authorizationEndpoint = `${environment.apiDomain.authorizationEndpoint}/users`;
        this.RETRY_COUNT = 0;
        this.REPLAY_COUNT = 10;
        this.tokenReceived = new Observable();
        this.promises = [];
        this.isReady = false;
    }
    returnPromises() {
        while (this.promises.length > 0) {
            const pr = this.promises.pop();
            const accessToken = this._oauthService.getAccessToken();
            const decoded = JWT(accessToken);
            this.getCurrentUser();
        }
        this.isReady = true;
    }
    handleError(error, injector) {
        // console.error('Có lỗi xảy ra', error);
        if (error.status === 401) {
            const authenService = injector.get(VsAuthenService);
            authenService.logout();
        }
        else {
        }
        return Promise.reject(error);
    }
    changePassword(item) {
        const apiUrl = `${this.authenticationEndpoint}/ChangePassword`;
        return this.defaultPost(apiUrl, item);
    }
    CreateCB(obj) {
        const queryString = `${this.serviceUri}/CreateCB`;
        return this.defaultPost(queryString, obj);
    }
    // ApproveTDV(obj: any) {
    //    const queryString = `${this.authenticationEndpoint}/ApproveTdv`;
    //    return this.defaultPost(queryString, obj);
    // }
    // RemoveTDV(obj: any) {
    //    const queryString = `${this.authenticationEndpoint}/RemoveTdv`;
    //    return this.defaultPost(queryString, obj);
    // }
    // UpdateCB(obj: any) {
    //    const queryString = `${this.authenticationEndpoint}/UpdateCB`;
    //    return this.defaultPost(queryString, obj);
    // }
    getUsersByIdClient(id) {
        const url = `${this.serviceUri}/getUsersByIdClient?idClient=${id}`;
        return this.defaultGet(url);
    }
    getUsersByIdShop(id) {
        const url = `${this.serviceUri}/getUsersByIdShop?idShop=${id}`;
        return this.defaultGet(url);
    }
    UpdateGeneral(obj) {
        const queryString = `${this.serviceUri}/UpdateGeneral`;
        return this.defaultPost(queryString, obj);
    }
    UpdateOmicall(obj) {
        const queryString = `${this.serviceUri}/UpdateOmicall`;
        return this.defaultPost(queryString, obj);
    }
    UpdateBank(obj, code) {
        const queryString = `${this.serviceUri}/UpdateBank?code=${code}`;
        return this.defaultPost(queryString, obj);
    }
    UpdateCode(obj) {
        const queryString = `${this.serviceUri}/UpdateCode`;
        return this.defaultPost(queryString, obj);
    }
    UpdateCmt(obj) {
        const queryString = `${this.serviceUri}/UpdateCmt`;
        return this.defaultPost(queryString, obj);
    }
    GetsListKOL(key) {
        const url = `${this.serviceUri}/GetsListKOL?key=${key}`;
        return this.defaultGet(url);
    }
    GetsListLeader(key) {
        const url = `${this.serviceUri}/GetsListLeader?key=${key}`;
        return this.defaultGet(url);
    }
    getCaptchaUrl() {
        return `${this.authenticationEndpoint}/captcha?${Date.now()}&logSession=${sessionStorage.getItem('log_session_key')}`;
    }
    getBasicUserInfo() {
        const crrUser = new User();
        const accessToken = this._oauthService.getAccessToken();
        if (accessToken) {
            const claims = JWT(accessToken);
            if (claims) {
                crrUser.displayName = claims.displayname;
                crrUser.email = claims.email;
                // crrUser.fullName = claims.firstname.concat(' ', claims.lastname);
                crrUser.issuperuser = claims.issuperuser.toLowerCase() === 'true';
                crrUser.permissions = claims.permissions;
                crrUser.roleassign = claims.roleassign;
                crrUser.scope = claims.scope;
                crrUser.userId = claims.sub;
                crrUser.userName = claims.username;
                crrUser.avatar = claims.avatar;
                crrUser.roleassign = claims.roleassign; //list roles
            }
        }
        return crrUser;
    }
    getCurrentUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const crrUser = new User();
            const accessToken = this._oauthService.getAccessToken();
            if (accessToken) {
                const claims = JWT(accessToken);
                if (claims) {
                    crrUser.displayName = claims.displayname;
                    crrUser.email = claims.email;
                    // crrUser.fullName = claims.firstname.concat(' ', claims.lastname);
                    crrUser.issuperuser = claims.issuperuser.toLowerCase() === 'true';
                    crrUser.permissions = claims.permissions;
                    crrUser.scope = claims.scope;
                    crrUser.userId = claims.sub;
                    crrUser.idClient = claims.idClient;
                    crrUser.idShop = claims.idShop;
                    crrUser.idPortal = claims.idPortal;
                    crrUser.userName = claims.username;
                    crrUser.avatar = claims.avatar; //this._commonService.getFileUrl(claims.avatar);
                    crrUser.roleassign = claims.roleassign; //list roles
                    if (localStorage.getItem(this.USER_INFO_KEY)) {
                        try {
                            return JSON.parse(localStorage.getItem(this.USER_INFO_KEY));
                        }
                        catch (e) {
                        }
                    }
                    else {
                        yield this.getCurrent().then(rs => {
                            if (rs.status) {
                                crrUser.email = rs.data.email;
                                crrUser.avatar = rs.data.avatar;
                                crrUser.id = rs.data.id;
                                crrUser.idClient = rs.data.idClient;
                                crrUser.idShop = rs.data.idShop;
                                crrUser.name = rs.data.name;
                                crrUser.phone = rs.data.phone;
                                crrUser.idProvince = rs.data.idProvince;
                                crrUser.idDistrict = rs.data.idDistrict;
                                crrUser.idWard = rs.data.idWard;
                                crrUser.address = rs.data.address;
                                crrUser.isDeleted = rs.data.isDeleted;
                                crrUser.idBank = rs.data.idBank;
                                crrUser.bankFullName = rs.data.bankFullName;
                                crrUser.bankNumber = rs.data.bankNumber;
                                crrUser.bankCardNumber = rs.data.bankCardNumber;
                                crrUser.bankBranch = rs.data.bankBranch;
                                crrUser.idBankNavigation = rs.data.idBankNavigation;
                                crrUser.isOmiCall = rs.data.isOmiCall;
                                crrUser.omiCallSipUser = rs.data.omiCallSipUser;
                                crrUser.omiCallSecretKey = rs.data.omiCallSecretKey;
                                crrUser.omiCallDomain = rs.data.omiCallDomain;
                                localStorage.setItem(this.USER_INFO_KEY, JSON.stringify(crrUser));
                            }
                        });
                    }
                    return JSON.parse(localStorage.getItem(this.USER_INFO_KEY));
                }
            }
            return crrUser;
        });
    }
    PickSupportCTV(userId) {
        const queryString = `${this.serviceUri}/PickSupportCTV?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    RemoveSupportCTV(userId) {
        const queryString = `${this.serviceUri}/RemoveSupportCTV?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    changeIdType(userId, idType) {
        const queryString = `${this.serviceUri}/ChangeIdType?userId=${userId}&idType=${idType}`;
        return this.defaultPost(queryString, {});
    }
    PickRef(user, userId) {
        const queryString = `${this.serviceUri}/PickRef?user=${user}&userId=${userId}`;
        return this.defaultGet(queryString);
    }
    RemovePickRef(user, userId) {
        const queryString = `${this.serviceUri}/RemovePickRef?user=${user}&userId=${userId}`;
        return this.defaultGet(queryString);
    }
    RemoveSupportCTVForAdmin(userId) {
        const queryString = `${this.serviceUri}/RemoveSupportCTVForAdmin?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    onSetLogout(userId, approve) {
        const queryString = `${this.serviceUri}/SetLogout?userId=${userId}&approve=${approve}`;
        return this.defaultGet(queryString);
    }
    Approved(userId, approve) {
        const queryString = `${this.serviceUri}/Approved`;
        const item = { userId: userId, approve: approve };
        return this.defaultPost(queryString, item);
    }
    ApprovedCmt(userId, approve) {
        const queryString = `${this.serviceUri}/ApprovedCmt`;
        const item = { userId: userId, approve: approve };
        return this.defaultPost(queryString, item);
    }
    AdminClient(userId, approve) {
        const queryString = `${this.serviceUri}/AdminClient`;
        const item = { userId: userId, approve: approve };
        return this.defaultPost(queryString, item);
    }
    setKPITdv(kpiSales, kpiVisit, kpiCoverage, kpiOrder, userId, idUserGroup) {
        const queryString = `${this.serviceUri}/SetKPITdv`;
        let params = new HttpParams()
            .append('kpiSales', kpiSales.toString())
            .append('kpiVisit', kpiVisit.toString())
            .append('kpiCoverage', kpiCoverage.toString())
            .append('kpiOrder', kpiOrder.toString());
        if (userId !== undefined && userId !== null) {
            params = params.append('userId', userId.toString());
        }
        if (idUserGroup !== undefined && idUserGroup !== null) {
            params = params.append('idUserGroup', idUserGroup.toString());
        }
        return this._http.post(queryString, null, { params }).toPromise();
    }
    GetByListId(listId) {
        const queryString = `${this.serviceUri}/GetByListId?listId=${listId}`;
        return this.defaultGet(queryString);
    }
    AutoComplete(key, offset, limit) {
        const queryString = `${this.serviceUri}/AutoComplete?key=${key}&offset=${offset}&limit=${limit}`;
        return this.defaultGet(queryString);
    }
    AutoCompleteByRef(key, except, userId, offset, limit) {
        const queryString = `${this.serviceUri}/AutoCompleteByRef?key=${key}&except=${except}&userId=${userId}&offset=${offset}&limit=${limit}`;
        return this.defaultGet(queryString);
    }
    AutoCompleteWithChoise(key, except, userId, idUserGroups, idUserTypes, idLocation, offset, limit) {
        const queryString = `${this.serviceUri}/AutoCompleteWithChoise?key=${key}&except=${except}&userId=${userId}&idUserGroups=${idUserGroups}&idUserTypes=${idUserTypes}&idLocation=${idLocation}&offset=${offset}&limit=${limit}`;
        return this.defaultGet(queryString);
    }
    SearchNotInClient(key, offset, limit, sortField, isAsc = false) {
        const queryString = `${this.serviceUri}/SearchNotInClient?key=${key}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    SearchInClient(key, idClient, offset, limit, sortField, isAsc = false) {
        const queryString = `${this.serviceUri}/SearchInClient?key=${key}&idClient=${idClient}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    gets(key, userId, status, offset, limit, sortField, isAsc = false, isApproved = -1, idType = -1, isCheckingUser = false) {
        const queryString = `${this.serviceUri}?key=${key}&userId=${userId}&status=${status}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}&isApproved=${isApproved}&idType=${idType}&isCheckingUser=${isCheckingUser}`;
        return this.defaultGet(queryString);
    }
    GetsInvited(key, offset, limit, sortField, isAsc = false) {
        const queryString = `${this.serviceUri}/GetsInvited?key=${key}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    GetsInvitedKOLPlus(userId, key, fromDate, toDate, idProvince, offset, limit, sortField, isAsc = false) {
        let fDate;
        let tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }
        const queryString = `${this.serviceUri}/GetsInvitedKOLPlus?userId=${userId}&key=${key}&fromDate=${fDate}&toDate=${tDate}&offset=${offset}&idProvince=${idProvince}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    GetsForSupport(key, idSupport, status, offset, limit, sortField, isAsc = false) {
        const queryString = `${this.serviceUri}/GetsForSupport?key=${key}&idSupport=${idSupport}&status=${status}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    GetsForSupportCSKH(key, fromDate, toDate, idSupport, idProvince, status, isKol, userGroup, userType, idRef, offset, limit, sortField, isAsc = false) {
        let fDate;
        let tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }
        const queryString = `${this.serviceUri}/GetsForSupportCSKH?key=${key}&fromDate=${fDate}&toDate=${tDate}&idSupport=${idSupport}&idProvince=${idProvince}&status=${status}&isKol=${isKol}&isKol=${isKol}&idUserGroup=${userGroup}&idUserType=${userType}&idRef=${idRef}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    GetByRoleName(RoleCode, offset, limit) {
        const queryString = `${this.authorizationEndpoint}/GetByRoleName?RoleCode=${RoleCode}&offset=${offset}&limit=${limit}`;
        return this.defaultGet(queryString);
    }
    managerment(term, pageIndex, pageSize, isActive, roleId, isDisable, isSuperUser) {
        const queryString = `${this.authorizationEndpoint}/managerment?term=${term}&pageIndex=${pageIndex}&pageSize=${pageSize}&isActive=${isActive}&roleId=${roleId}&isDisable=${isDisable}&isSuperUser=${isSuperUser}`;
        return this.defaultGet(queryString);
    }
    SearchNotInGroup(key, offset, limit, sortField, isAsc = false) {
        const queryString = `${this.serviceUri}/SearchNotInGroup?key=${key}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    getCurrent() {
        return this.defaultGet(`${this.serviceUri}/getCurrent`);
    }
    GetByUserId(userId) {
        return this.defaultGet(`${this.serviceUri}/GetByUserId?userId=${userId}`);
    }
    GetListTn(key, offset, limit, sortField, isAsc = false) {
        const queryString = `${this.serviceUri}/GetListTn?key=${key}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    GetByDomain(key, domainStatus, offset, limit, sortField = '', isAsc = 0) {
        const queryString = `${this.serviceUri}/GetByDomain?key=${key}&domainStatus=${domainStatus}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    LoadConfig() {
        return this.defaultGet(`${this.serviceUri}/LoadConfig`);
    }
    UpdateIsLogout() {
        return this.defaultGet(`${this.serviceUri}/UpdateIsLogout`);
    }
    GetListUserSip() {
        const queryString = `${this.serviceUri}/GetListUserSip`;
        return this.defaultGet(queryString);
    }
    GetListUserInstructor() {
        const queryString = `${this.serviceUri}/GetListUserInstructor`;
        return this.defaultGet(queryString);
    }
    GetShort() {
        const queryString = `${this.serviceUri}/GetShort`;
        return this.defaultGet(queryString);
    }
    GetShortByPharmacies(userId, offset, limit) {
        const queryString = `${this.serviceUri}/GetShortByPharmacies?userId=${userId}&offset=${offset}&limit=${limit}`;
        return this.defaultGet(queryString);
    }
    GetShortTdvByPharmacies(userId, offset, limit) {
        const queryString = `${this.serviceUri}/GetShortTdvByPharmacies?userId=${userId}&offset=${offset}&limit=${limit}`;
        return this.defaultGet(queryString);
    }
    GetCustomerCSKH(key, userId, idType, idProvince, idUserType, idUserGroup, isDeleted, isApproved, offset, limit, sortField, isAsc = false) {
        const queryString = `${this.serviceUri}/GetCustomerCSKH?key=${key}&userId=${userId}&idType=${idType}&idProvince=${idProvince}&idUserType=${idUserType}&idUserGroup=${idUserGroup}&isDeleted=${isDeleted}&isApproved=${isApproved}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    GetByPharmacies(key, fromDate, toDate, idProvince, idDistrict, idUserType, idUserGroup, isApproved, offset, limit, sortField, isAsc = false) {
        const queryString = `${this.serviceUri}/GetByPharmacies?key=${key}&fromDate=${fromDate}&toDate=${toDate}&idProvince=${idProvince}&idDistrict=${idDistrict}&idUserType=${idUserType}&idUserGroup=${idUserGroup}&isApproved=${isApproved}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    ViewLocationInMap(key, fromDate, toDate, userId, idProvince, idUserType, idUserGroup, offset, limit, sortField, isAsc = false) {
        const queryString = `${this.serviceUri}/ViewLocationInMap?key=${key}&fromDate=${fromDate}&toDate=${toDate}&userId=${userId}&idProvince=${idProvince}&idUserType=${idUserType}&idUserGroup=${idUserGroup}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    SaveTemp(item) {
        return this._http
            .post(`${this.serviceUri}/SaveTemp`, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    GetUserTempById(userId) {
        return this.defaultGet(`${this.serviceUri}/GetUserTempById?userId=${userId}`);
    }
    AutocompleteUsersEditOrder(key, offset, limit) {
        const queryString = `${this.serviceUri}/AutocompleteUsersEditOrder?key=${key}&offset=${offset}&limit=${limit}`;
        return this.defaultGet(queryString);
    }
    GetUsersEditOrder(userId) {
        const queryString = `${this.serviceUri}/GetUsersEditOrder?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    GetTaxUpdateOrder(userId) {
        const queryString = `${this.serviceUri}/GetTaxUpdateOrder?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    GetTax(userId) {
        const queryString = `${this.serviceUri}/GetTax?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    AssignCertificateType(userId, id, optionAssign) {
        const queryString = `${this.serviceUri}/AssignCertificateType`;
        let params = new HttpParams()
            .append('userId', userId.toString())
            .append('id', id.toString())
            .append('optionAssign', optionAssign.toString());
        return this._http.post(queryString, null, { params }).toPromise();
    }
    GetListAsmRsm() {
        var queryString = `${this.serviceUri}/GetListAsmRsm`;
        return this.defaultGet(queryString);
    }
    GetTdvByOrganization() {
        var queryString = `${this.serviceUri}/GetTdvByOrganization`;
        return this.defaultGet(queryString);
    }
}
