import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ComponentBase } from '../../../classes/base/component-base';
import { UserService } from '../../../services/user.service';
import { DynamicDialogConfig } from 'primeng/primeng';
export class FileViewerComponent extends ComponentBase {
    constructor(config, _UserService) {
        super();
        this.config = config;
        this._UserService = _UserService;
        this.scale = 100;
        this.top = 0;
        this.left = 0;
        this.dragging = false;
        this.rotation = 0;
        this.lastMouseX = 0;
        this.lastMouseY = 0;
        this.offsetX = 0;
        this.offsetY = 0;
        this.isPopupVisible = false;
        this.popupPosition = { x: 0, y: 0 };
        this.showFileDialog = false;
        this.selectedIndex = 0;
        this.idPharmacy = -1;
        this.listFile = [];
        this.file = {
            filenName: '',
            // path: '',
            type: 1,
            url: ''
        };
        this.commands = [
            { label: "Gán cho giấy tờ DKDN", action: 1 },
            { label: "Gán cho giấy chứng nhận DDKKDD", action: 2 },
            { label: "Gán cho GDP/GPP/GSP", action: 3 }
        ];
    }
    ngOnInit() {
        // this.lstImages = this.config.data.lstImages;
        // this.showPopup(this.lstImages[0], this.lstImages);
        //window.addEventListener('wheel', this.onWheel.bind(this), { passive: false });
    }
    // ngOnDestroy(): void {
    //     window.removeEventListener('wheel', this.onWheel.bind(this));
    // }
    // Sử dụng với file list idFile
    showPopup(idFile, data = []) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.listFile = [];
            this.file.type = 1;
            this.file.url = yield this.getImageOriginal(idFile);
            if (data.length > 0) {
                data.forEach(item => {
                    this.listFile.push({ id: item, url: this.getImageOriginal(item), type: 1 });
                });
                this.file = this.listFile.find(s => s.id == idFile); // Gán lại file trong list để hàm next tìm được
            }
            // const fileType = this.getFileType(this.FileName);
            // const documentType = this.getDocumentType(fileType);
            // if (documentType == 'image') {
            // this.isImage = true;
            // }
            // else {
            //     this.isImage = false;
            //     this.initDocumentViewer(fileType, documentType);
            // }
            this.showFileDialog = true;
        });
    }
    // Sử dụng với List Object File
    showPopupObject(file, data = []) {
        if (data.length > 0) {
            this.listFile = data.filter(x => x.type == 1); // Chỉ view file image và video
        }
        this.file = file;
        if (this.file.type == 3)
            this.file.url = `${environment.apiDomain.fileEndpoint}${this.file.path}`;
        else
            this.file.url = this.urlImageOriginal(this.file.path);
        this.showFileDialog = true;
        this.idPharmacy = file.idPharmacy;
    }
    getCurrent(item, index) {
        const currentIndex = this.listFile.indexOf(item);
        this.selectedIndex = currentIndex;
        this.file = this.listFile[currentIndex];
        this.file.url = item.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.scale = 100;
        this.rotation = 0;
    }
    onNext() {
        this.scale = 100;
        this.rotation = 0;
        if (this.file == null || this.file.path == "" || this.listFile == null || this.listFile.length <= 0)
            return;
        const currentIndex = this.listFile.indexOf(this.file);
        const nextIndex = (currentIndex + 1) % this.listFile.length;
        this.file = this.listFile[nextIndex];
        this.file.url = this.file.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.selectedIndex = nextIndex;
    }
    onPrev() {
        this.scale = 100;
        this.rotation = 0;
        if (this.file == null || this.file.path == "" || this.listFile == null || this.listFile.length <= 0)
            return;
        const currentIndex = this.listFile.indexOf(this.file);
        let prevIndex = 0;
        if (currentIndex <= 0)
            prevIndex = this.listFile.length - 1;
        else
            prevIndex = currentIndex - 1;
        // prevIndex = (currentIndex - 1) % this.listFile.length;
        this.file = this.listFile[prevIndex];
        this.file.url = this.file.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.selectedIndex = prevIndex;
    }
    onKeydownHandler(event) {
        // event.preventDefault();
        if (event.key === 'ArrowLeft') {
            //if (event.keyCode == 37) {
            this.onPrev();
        }
        if (event.key === 'ArrowRight') {
            this.onNext();
        }
    }
    // urlFile(file) {
    //     if (file.path == null || file.path == undefined)
    //         return `/assets/images/no_image.jpg`; 
    //     return `https://docs.google.com/viewerng/viewer?url=https://wmapi.friends.vn/file.api//2023/9/23/mickey-tai-lieu-api_20230923115008708.docx`;
    // }
    // command menu
    openPopup(event, item) {
        if (this.idPharmacy > 0) {
            event.preventDefault();
        }
        let posX = event.clientX;
        let posY = event.clientY;
        const popupWidth = 150;
        const popupHeight = 100;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        if (posX + popupWidth > viewportWidth) {
            posX = viewportWidth - popupWidth;
        }
        if (posY + popupHeight > viewportHeight) {
            posY = viewportHeight - popupHeight;
        }
        this.popupPosition = { x: posX, y: posY };
        this.isPopupVisible = true;
    }
    command_option(label, actionType, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isConfirmed = window.confirm(`Bạn có chắc chắn muốn thực hiện lệnh: ${label}?`);
            if (isConfirmed) {
                yield this._UserService.AssignCertificateType(this.idPharmacy, file.id, actionType)
                    .then(response => {
                    console.log('API thành công:', response);
                })
                    .catch(error => {
                    console.error('API lỗi:', error);
                });
                this.isPopupVisible = false;
            }
            console.log(this.idPharmacy);
            console.log(file.id);
        });
    }
    closePopup() {
        this.isPopupVisible = false;
    }
    // zoom ảnh
    onMouseDown(event, image) {
        if (event.button !== 0) {
            return;
        }
        this.dragging = true;
        this.lastMouseX = event.clientX;
        this.lastMouseY = event.clientY;
        image.style.cursor = 'grabbing';
    }
    onMouseMove(event) {
        if (!this.dragging)
            return;
        const deltaX = event.clientX - this.lastMouseX;
        const deltaY = event.clientY - this.lastMouseY;
        // this.left += deltaX;
        //this.top += deltaY;
        this.offsetX += deltaX;
        this.offsetY += deltaY;
        this.lastMouseX = event.clientX;
        this.lastMouseY = event.clientY;
    }
    onMouseUp() {
        this.dragging = false;
    }
    onWheel(event) {
        const isInside = this.container.nativeElement.contains(event.target);
        if (isInside) {
            event.preventDefault();
            let newScale = this.scale - event.deltaY * 0.2;
            newScale = Math.max(newScale, 100);
            newScale = Math.min(newScale, 500);
            this.scale = newScale;
        }
    }
    rotateImage(angle) {
        this.rotation = (this.rotation + angle) % 360; // Xoay ảnh, giữ giá trị trong khoảng 0-360
    }
}
