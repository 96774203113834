import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ComponentBase } from '../../../classes/base/component-base';
import { UserService } from '../../../services/user.service';
import { DynamicDialogConfig } from 'primeng/primeng';
@Component({
    selector: 'file-viewer',
    templateUrl: './file-viewer.component.html',
    styleUrls: ['./file-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FileViewerComponent extends ComponentBase implements OnInit {
    @ViewChild('container') container!: ElementRef;
    scale = 100;
    top = 0;
    left = 0;
    dragging = false;
    rotation: number = 0;
    lastMouseX: number = 0;
    lastMouseY: number = 0;

    offsetX: number = 0;
    offsetY: number = 0;

    isPopupVisible = false;
    popupPosition = { x: 0, y: 0 };

    showFileDialog = false;
    selectedIndex: number = 0;
    idPharmacy: number = -1;

    listFile: any[] = [];
    file: any = {
        filenName: '',
        // path: '',
        type: 1,
        url: ''
    };

    commands = [
        { label: "Gán cho giấy tờ DKDN", action: 1 },
        { label: "Gán cho giấy chứng nhận DDKKDD", action: 2 },
        { label: "Gán cho GDP/GPP/GSP", action: 3 }
    ];
    lstImages: any;

    constructor(
        public config: DynamicDialogConfig,
        public _UserService: UserService,
    ) {
        super();
    }

    ngOnInit(): void {
        // this.lstImages = this.config.data.lstImages;
        // this.showPopup(this.lstImages[0], this.lstImages);
        //window.addEventListener('wheel', this.onWheel.bind(this), { passive: false });
    }

    // ngOnDestroy(): void {
    //     window.removeEventListener('wheel', this.onWheel.bind(this));
    // }

    // Sử dụng với file list idFile
    async showPopup(idFile: string, data: any[] = []) {
        this.listFile = [];

        this.file.type = 1;
        this.file.url = await this.getImageOriginal(idFile);

        if (data.length > 0) {
            data.forEach(item => {
                this.listFile.push({ id: item, url: this.getImageOriginal(item), type: 1 });
            });

            this.file = this.listFile.find(s => s.id == idFile);    // Gán lại file trong list để hàm next tìm được
        }

        // const fileType = this.getFileType(this.FileName);
        // const documentType = this.getDocumentType(fileType);
        // if (documentType == 'image') {
        // this.isImage = true;
        // }
        // else {
        //     this.isImage = false;
        //     this.initDocumentViewer(fileType, documentType);
        // }
        this.showFileDialog = true;
    }

    // Sử dụng với List Object File
    showPopupObject(file: any, data: any[] = []) {

        if (data.length > 0) {
            this.listFile = data.filter(x => x.type == 1);  // Chỉ view file image và video
        }

        this.file = file;
        if (this.file.type == 3)
            this.file.url = `${environment.apiDomain.fileEndpoint}${this.file.path}`;
        else
            this.file.url = this.urlImageOriginal(this.file.path);

        this.showFileDialog = true;
        this.idPharmacy = file.idPharmacy;
    }

    getCurrent(item: any, index: number) {
        const currentIndex = this.listFile.indexOf(item);
        this.selectedIndex = currentIndex;
        this.file = this.listFile[currentIndex];
        this.file.url = item.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.scale = 100;
        this.rotation = 0;
    }

    onNext() {
        this.scale = 100;
        this.rotation = 0;
        if (this.file == null || this.file.path == "" || this.listFile == null || this.listFile.length <= 0) return;
        const currentIndex = this.listFile.indexOf(this.file);

        const nextIndex = (currentIndex + 1) % this.listFile.length;
        this.file = this.listFile[nextIndex];
        this.file.url = this.file.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.selectedIndex = nextIndex;
    }

    onPrev() {
        this.scale = 100;
        this.rotation = 0;
        if (this.file == null || this.file.path == "" || this.listFile == null || this.listFile.length <= 0) return;
        const currentIndex = this.listFile.indexOf(this.file);
        let prevIndex = 0;
        if (currentIndex <= 0)
            prevIndex = this.listFile.length - 1;
        else
            prevIndex = currentIndex - 1;

        // prevIndex = (currentIndex - 1) % this.listFile.length;
        this.file = this.listFile[prevIndex];
        this.file.url = this.file.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.selectedIndex = prevIndex;
    }

    @HostListener('window:keydown', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        // event.preventDefault();
        if (event.key === 'ArrowLeft') {
            //if (event.keyCode == 37) {
            this.onPrev();
        }
        if (event.key === 'ArrowRight') {
            this.onNext();
        }
    }

    // urlFile(file) {
    //     if (file.path == null || file.path == undefined)
    //         return `/assets/images/no_image.jpg`; 
    //     return `https://docs.google.com/viewerng/viewer?url=https://wmapi.friends.vn/file.api//2023/9/23/mickey-tai-lieu-api_20230923115008708.docx`;
    // }

    // command menu

    openPopup(event: MouseEvent, item: any) {

        if (this.idPharmacy > 0) {
            event.preventDefault();
        }

        let posX = event.clientX;
        let posY = event.clientY;

        const popupWidth = 150;
        const popupHeight = 100;

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        if (posX + popupWidth > viewportWidth) {
            posX = viewportWidth - popupWidth;
        }

        if (posY + popupHeight > viewportHeight) {
            posY = viewportHeight - popupHeight;
        }

        this.popupPosition = { x: posX, y: posY };
        this.isPopupVisible = true;
    }

    async command_option(label: string, actionType: number, file: any) {
        const isConfirmed = window.confirm(`Bạn có chắc chắn muốn thực hiện lệnh: ${label}?`);
        if (isConfirmed) {
            await this._UserService.AssignCertificateType(this.idPharmacy, file.id, actionType)
                .then(response => {
                    console.log('API thành công:', response);
                })
                .catch(error => {
                    console.error('API lỗi:', error);
                });
            this.isPopupVisible = false;
        }

        console.log(this.idPharmacy);
        console.log(file.id);
    }

    @HostListener('document:click')
    closePopup() {
        this.isPopupVisible = false;
    }


    // zoom ảnh
    onMouseDown(event: MouseEvent, image: HTMLImageElement): void {

        if (event.button !== 0) {
            return;
        }

        this.dragging = true;
        this.lastMouseX = event.clientX;
        this.lastMouseY = event.clientY;

        image.style.cursor = 'grabbing';
    }

    onMouseMove(event: MouseEvent): void {
        if (!this.dragging) return;

        const deltaX = event.clientX - this.lastMouseX;
        const deltaY = event.clientY - this.lastMouseY;

        // this.left += deltaX;
        //this.top += deltaY;
        this.offsetX += deltaX;
        this.offsetY += deltaY;


        this.lastMouseX = event.clientX;
        this.lastMouseY = event.clientY;
    }

    onMouseUp(): void {
        this.dragging = false;
    }
    @HostListener('window:wheel', ['$event'])
    onWheel(event: WheelEvent): void {
        const isInside = this.container.nativeElement.contains(event.target as Node);

        if (isInside) {
            event.preventDefault();
            let newScale = this.scale - event.deltaY * 0.2;
            newScale = Math.max(newScale, 100);
            newScale = Math.min(newScale, 500);

            this.scale = newScale;
        }
    }

    rotateImage(angle: number): void {
        this.rotation = (this.rotation + angle) % 360; // Xoay ảnh, giữ giá trị trong khoảng 0-360
    }
}
