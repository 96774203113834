import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
import { environment } from '../../../../../environments/environment';
import { ComponentBase } from '../../../classes/base/component-base';

declare var DocsAPI: any;

@Component({
  selector: 'app-vs-file-viewer',
  templateUrl: './vs-file-viewer.component.html',
  styleUrls: ['./vs-file-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VsFileViewerComponent extends ComponentBase implements OnInit {

  listFile: any[] = [];
  file: any = {
    filenName: '',
    path: '',
    type: 1,
    url: ''
  };

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
    super();
  }

  ngOnInit() {
    const file = this.config.data;
    const fileType = this.getFileType(file.fileName);
    const documentType = this.getDocumentType(fileType);

    const configsssss = {
      'documentType': `${documentType}`,
      'document': {
        'fileType': `${fileType}`,
        "key": `${file.id}`,
        'title': `${file.fileName}`,
        'url': `${environment.apiDomain.fileEndpoint}${file.path}`
      },
      "editorConfig": {
        "mode": "view",
      }
      // "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.e30.LwimMJA3puF3ioGeS-tfczR3370GXBZMIL-bdpu4hOU"
    };
    const docEditor = new DocsAPI.DocEditor('fileviewer', configsssss);
  }

  @HostListener('window:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    // event.preventDefault();
    if (event.key === 'Escape') {
      this.ref.close(null);
    }
  }

  closePopup() {
    this.ref.close(null);
  }

  getFileType(fileName: string) {
    const arr = fileName.split('.');
    return arr[arr.length - 1];
  }

  getDocumentType(fileType: string) {
    if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
      return 'image';
    } else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
      return 'text';
    } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
      return 'spreadsheet';
    } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
      return 'presentation';
    }

    return '';
  }


}

